import React, { Component, state, toggleButtonState } from 'react';
import logo from './logo.svg';
import './App.css';

// function fetch() {
//   return new Promise(resolve => setTimeout(() => resolve(42), 1000));
// }

function fetchAPI() {
  return fetch("https://us-central1-attentionbuttonapp.cloudfunctions.net/demo");
}

class App extends Component {

  state = {
    sending: false,
    sent: false
  }

  toggleButtonState = () => {
    this.setState({ sent: true });
    fetchAPI()
      .then(() => {
        // this.setState({ sent: true });
      });
  }


  render() {
    const sending = this.state.sending;
    const sent = this.state.sent;
    let notice;

    const buttonStyle = {
      borderRadius: 6,
      fontSize: 30,
      padding: 12,
      fontWeight: "bold",
      color: "#1b1b1b",
      backgroundColor: "#fb8c00",
      borderWidth: 0.5
    };

    if (sent) {
      notice = <p>Request sent, thanks!</p>
    } else if (sending) {
      notice = <p>Request sending!</p>
    }

    return (
      <div className="App">
        <header className="App-header">
          {!sent &&
            <div>
              <button style={buttonStyle} onClick={this.toggleButtonState}>
                Get Kyle's Attention!
              </button>
              <p>
                Click the button above to get Kyle's attention!
              </p>
            </div>
          }
          <div>
            {notice}
          </div>
        </header>
      </div>
    );
  }
}

export default App;

// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function fetch() {
//   return new Promise(resolve => setTimeout(() => resolve(42), 1000));
// }

// function fetchAPI() {
//   return fetch("https://www.google.com");
// }

// function App() {
//   state = {
//     sent: false
//   }

//   triggerDemo = () => {
//     fetchAPI()
//       .then(() => {
//         this.setState({ sent: true })
//       })
//   }

//   return (
//     <div className="App">
//       <header className="App-header">
//         <button onClick={this.triggerDemo}>
//           Get Kyle's Attention!
//         </button>
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
